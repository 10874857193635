<template>
  <header class="home-banner">
    <div class="home-banner__content">
      <h1 class="home-banner__title" v-scroll-reveal="{delay: 100}">{{ content.title }}</h1>
      <p v-scroll-reveal="{delay: 200}">{{ content.description }}</p>
      <div class="home-banner__links">
        <LangRouterLink :to="{ name: 'participate' }" class="button --primary --short" v-scroll-reveal="{delay: 250}">{{ $t('general.participate') }}</LangRouterLink>
        <LangRouterLink :to="{ name: 'about' }" class="home-banner__link" v-scroll-reveal="{delay: 300}">
          {{ $t('general.center') }}
          <Icon id="long-arrow" class="icon-test"/>
        </LangRouterLink>
      </div>
    </div>
    <div class="banner__image" v-scroll-reveal>
      <div class="banner__image-fade"></div>
      <img :src="content.image" alt="" class="banner__image-img">
    </div>
    <ul class="home-banner__people">
      <li v-for="(type, key) in content.people" :key="key" class="home-banner__person" v-scroll-reveal="{delay: 100 * key + 250}">
        <img :src="type.image" alt="" class="home-banner__person-icon">
        <h2>{{ type.name }}</h2>
        <p>{{ type.description }}</p>
        <LangRouterLink :to="{ name: type.link }">
          <span class="home-banner__person-link">{{ learnMore[key]  }}</span>
        </LangRouterLink>
      </li>
    </ul>
  </header>
</template>

<script>
import { Icon, LangRouterLink } from '@/components/global';
export default {
  name: 'HomeBanner',
  components: {
    Icon,
    LangRouterLink
  },
  props: {
    content: {
      type: Object,
      default: null
    }
  },
  computed: {
    learnMore() {
      return [this.$t('general.learnMore'), this.$t('general.learnMoreRead')]
    }
  }
}
</script>
