<template>
  <ul class="mobile-nav-panel__contact">
    <li v-for="(info, infoKey) in contactInfos" :key="infoKey" class="mobile-nav-panel__contact-item">
      <Icon :id="info.icon" class="mobile-nav-panel__contact-icon"/>
      <a v-if="info.to" :href="info.to" class="mobile-nav-panel__contact-link">{{ info.title }}</a>
      <p v-else class="mobile-nav-panel__contact-text">{{ info.title }}</p>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'ContactInfos',
  computed: {
    contactInfos() {
      return [
        {
          icon: 'place',
          title: this.$beet.isReady ? this.$beet.options.general.localisation : '',
          to: false
        },
        {
          icon: 'mail',
          title: this.$beet.isReady ? this.$beet.options.general.email : '',
          to: this.$beet.isReady ? `mailto:${this.$beet.options.general.email}` : ''
        },
        {
          icon: 'phone',
          title: this.$beet.isReady ? this.$beet.options.general.cellphone : '',
          to: false
        }
      ]
    }
  }
}
</script>
