<template>
  <div class="share__group">
    <button @click="shareNavigator" class="share">
      <span class="share__text">{{ $t(`general.${this.type}.share`) }}</span>
      <Icon id="share" class="share__icon --mobile" />
    </button>
    <div class="share__medias">
      <button v-for="provider in providers"
              :key="provider"
              :aria-label="provider"
              @click="share(provider)"
      >
        <Icon :id="provider" class="share__icon" />
      </button>
    </div>
  </div>
</template>

<script>
import {Icon} from '@/components/global';
export default {
  name: 'MediaShare',
  components: {
    Icon
  },
  props: {
    title: { type: String, required: true },
    type: { type: String, required: true }
  },
  data() {
    return {
      providers: ['facebook', 'linkedin', 'twitter']
    }
  },
  methods: {
    shareNavigator() {
      navigator.share({ url: window.location.href})
    },
    share(provider) {
      const platforms = {
        facebook: [
          `https://facebook.com/sharer/sharer.php?u=${window.location.href}`,
          '_blank',
          'fullscreen=no, width=500, height=700, toolbar=no, status=no, titlebar=no,left=500px'
        ],
        twitter: [
          `https://twitter.com/intent/tweet?text=VITAM - ${this.title}&url=${window.location.href}`,
          '_blank',
          'fullscreen=no, width=500, height=700, toolbar=no, status=no, titlebar=no,left=500px'
        ],
        linkedin: [
          `https://www.linkedin.com/sharing/share-offsite/?summary=VITAM - ${this.title}&url=${window.location.href}`,
          '_blank',
          'fullscreen=no, width=500, height=700, toolbar=no, status=no, titlebar=no,left=500px'
        ]
      }
      window.open(...platforms[provider])
    }
  }
}
</script>
