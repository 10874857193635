import Icon from './Icon.vue'
import DropDown from './DropDown.vue'
import LangRouterLink from './LangRouterLink.vue'
import RawHtml from './RawHtml.vue'

const globals = [Icon, DropDown, RawHtml, LangRouterLink]

export { Icon, DropDown, RawHtml, LangRouterLink }

export default Vue => globals.forEach(component => Vue.component(component.name, component))
