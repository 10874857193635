<template>
  <section class="section">
    <TitleBlock :content="titleSection" class="--centered --max" />
    <ul class="partner__group">
      <li v-for="(partner, partnerKey) in content.partners" :key="partnerKey" class="partner" v-scroll-reveal="{delay: 200 * partnerKey + 400}">
        <img :src="partner.image" :alt="partner.name" :title="partner.name">
      </li>
    </ul>
    <a :href="`mailto:${content.email}`" target="_blank" class="button --primary --centered" v-scroll-reveal="{delay: 800}">{{ $t('general.partner') }}</a>
  </section>
</template>

<script>
import { TitleBlock } from "@/components";
export default {
  name: 'PartnersSection',
  components: {
    TitleBlock
  },
  props: {
    content: {
      type: Object,
      default: null
    }
  },
  computed: {
    titleSection() {
      return {
        description: this.content.description,
        subTitle: this.content.subTitle,
        title: this.content.title
      }
    }
  }
}
</script>
