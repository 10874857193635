export default {
    contact: {
        confirmation: 'Nous avons bien reçu votre message! Un membre de notre équipe vous répondra dans les meilleurs délais.',
        description: 'Notre équipe est toujours là pour vous aider.',
        errors: {
            email: {
                empty: 'Veuillez entrer une adresse courriel.',
                pattern: 'Veuillez entrer une adresse courriel valide.'
            },
            firstName: {
                empty: 'Veuillez entrer un prénom.',
                pattern: 'Veuillez entrer un prénom valide.'
            },
            message: {
                empty: 'Veuillez entrer un message.',
                pattern: 'Veuillez entrer un message valide. (Maximum 500 charactères)'
            },
            name: {
                empty: 'Veuillez entrer un nom.',
                pattern: 'Veuillez entrer un nom valide.'
            }
        },
        email: 'Adresse courriel',
        firstName: 'Prénom',
        message: 'Votre message',
        name: 'Nom',
        newsletter: 'Je veux m’inscrire à l’infolettre VITAM',
        submit: 'Envoyer le message',
        subTitle: 'Contactez-nous',
        title: 'Vous avez une question?'
    },
    filters: {
        allThemes: 'Toutes les thématiques',
        allTypes: 'Tous les types',
        events: {
            future: 'Événements à venir',
            past: 'Événements passés'
        },
        filter: 'Filtres',
        reset: 'Effacer',
        search: 'Recherche',
        searchDocument: 'Rechercher un document',
        searchMember: 'Rechercher un membre',
        themes: 'Thèmes',
        types: 'Types'
    },
    footer: {
        discover: 'Découvrir VITAM',
        newsletter: {
            title: 'Inscription à l\'infolettre',
            confirmation:  'Nous avons bien reçu votre inscription!',
            description: 'Inscrivez-vous à notre infolettre pour rester à l’affût de nos actualités',
            placeholder: 'Adresse courriel',
        }
    },
    general: {
        accessibility: 'Accessibilité',
        policy: 'Confidentialité',
        applicate: 'Postuler',
        application: 'Soumettre une candidature',
        careers: {
            empty: 'Il n\'y a aucune offre d\'emploi affichée présentement.'
        },
        center: 'Découvrir notre centre',
        channel: 'Consulter la chaîne',
        concept: 'En apprendre plus sur la santé durable',
        contact: 'Coordonnées',
        contactUs: 'Coordonnées',
        date: 'Date',
        details: 'Plus de détails',
        document: {
            empty: 'Aucun document disponible pour l\'instant.'
        },
        events: {
            all: 'Voir tous les événements',
            empty: 'Aucun événement disponible pour le moment.',
            future: {
                empty: 'Il n’y a présentement aucun événement à venir chez VITAM. N’hésitez pas à consulter la section Événements passés qui regorge de conférences à visionner!',
                link: 'Consulter les événements passés'
            },
            past: {
                empty: 'Il n’y a présentement aucun événement passé chez VITAM. N’hésitez pas à consulter la section Événements futurs qui regorge de conférences à visionner!',
                link: 'Consulter les événements futures'
            },
            return: 'Retour aux événements',
            share: 'Partager l\'événement',
            suggested: {
                subTitle: 'Jetez un œil à nos prochains événements',
                title: 'Restez à l\'affût'
            },
            view: 'Voir nos événements'
        },
        health: 'En apprendre plus sur la santé durable',
        home: 'Retourner à l\'accueil',
        learnMore: 'En savoir plus',
        learnMoreRead: 'En savoir plus',
        location: 'Lieu',
        managers: 'Responsable',
        members: {
            become: 'Devenir membre',
            profile: 'Voir le profil',
            return: 'Retour au bottin'
        },
        news: {
            all: 'Consulter toutes les actualités',
            empty: 'Il n\'y a aucune actualité dans cette catégorie.',
            return: 'Retour aux actualités',
            share: 'Partager l\'actualité',
            suggested: {
                subTitle: 'D\'autres actualités qui pourraient vous intéresser',
                title: 'Restez à l\'affût'
            },
        },
        notFound: 'Retour à l\'accueil',
        opportunities: 'Voir les postes disponibles',
        opportunitiesOpenings: 'Découvrir les emplois disponibles',
        participate: 'Participer',
        partner: 'Devenir partenaire',
        poles: 'Explorer les pôles',
        projects: 'Explorer les projets',
        readMore: 'Lire la suite',
        researchThemes: 'Thématiques de recherche',
        spontaneous: 'Soumettre une candidature spontanée',
        subscription: 'S\'inscrire',
        tips: 'Consulter tous les clins d\'oeils',
        view: 'Consulter',
        who: 'Découvrir notre centre'
    }
}
