<template>
  <section v-if="content" class="section">
    <TitleBlock :content="titleSection" />
    <LangRouterLink :to="{name: 'events'}" class="button --primary">{{ buttonLabel }}</LangRouterLink>
  </section>
</template>

<script>
import { LangRouterLink } from '@/components/global';
import { TitleBlock } from '@/components';
export default {
  name: 'SimpleTextCallTo',
  components: {
    TitleBlock,
    LangRouterLink
  },
  props: {
    content: {
      type: Object,
      default: null
    }
  },
  computed: {
    buttonLabel() {
      return this.content.buttonLabel
    },
    titleSection() {
      return {
        description: this.content.text,
        subTitle: this.content.title
      }
    }
  }
}
</script>
