<template>
  <ul class="mobile-nav-panel__medias">
    <li v-for="(media, mediaKey) in contactMedias" :key="mediaKey">
      <a :href="media.to" :title="media.title" target="_blank" class="mobile-nav-panel__medias-item">
        <Icon :id="media.icon" class="mobile-nav-panel__medias-icon"/>
      </a>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'ContactMedias',
  computed: {
    contactMedias() {
      return [
        {
          icon: 'linkedin',
          title: 'Linkedin',
          to: this.$beet.isReady ? this.$beet.options.social.linkedin : ''
        },
        {
          icon: 'twitter',
          title: 'Twitter',
          to: this.$beet.isReady ? this.$beet.options.social.twitter : ''
        },
        {
          icon: 'youtube',
          title: 'Youtube',
          to: this.$beet.isReady ? this.$beet.options.social.youtube : ''
        }
      ]
    }
  }
}
</script>
