<template>
  <section class="section" id="tips">
    <TitleBlock :content="titleSection" class="block--center --max" />
    <div class="button__group --center --mobile">
      <button class="button--round -reverse" :class="this.currentStep === 0 ? '-empty' : '-full'" @click="lessStep"><Icon id="arrow"/></button>
      <button class="button--round" :class="this.currentStep === this.maxStep ? '-empty' : '-full'" @click="moreStep"><Icon id="arrow"/></button>
    </div>
    <div class="tips__section" v-scroll-reveal>
      <button class="button--round -reverse -table" :class="this.currentStep === 0 ? '-empty' : '-full'" @click="lessStep"><Icon id="arrow"/></button>
      <ul v-if="tips">
        <TipCard v-for="(tip, tipKey) in tips" :key="tipKey" :content="tip" v-show="tipKey === currentStep" :detailed="true" />
      </ul>
      <button class="button--round -table" :class="this.currentStep === this.maxStep ? '-empty' : '-full'" @click="moreStep"><Icon id="arrow"/></button>
    </div>

  </section>
</template>

<script>
import { TitleBlock, TipCard } from '@/components';
export default {
  name: 'TipsDetailedSection',
  data() {
    return {
      currentStep: 0,
      time: 0,
    }
  },
  components: {
    TitleBlock,
    TipCard
  },
  props: {
    content: {
      type: Object,
      default: null
    }
  },
  computed: {
    maxStep() {
      return this.tips ? this.tips.length - 1 : 0
    },
    tips() {
      return this.content.tips
    },
    titleSection() {
      return {
        description: this.content.description,
        subTitle: this.content.subTitle,
        title: this.content.title
      }
    }
  },
  methods: {
    lessStep() {
      if(this.currentStep > 0) {
        this.currentStep--
        this.time = 0
      }
    },
    moreStep() {
      if(this.currentStep < this.maxStep) {
        this.currentStep++
        this.time = 0
      }
    }
  },
  watch: {
    time(newTime) {
      if(newTime === 10) this.moreStep()
    }
  },
  mounted() {
    window.setInterval(() => {
      this.time++
    }, 1000)
  }
}
</script>
