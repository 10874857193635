<template>
  <header class="banner" v-scroll-reveal>
    <div class="banner__text">
      <LangRouterLink v-if="route && route !== 'back'" :to="route" class="link__details --reverse">
        <Icon id="long-arrow" />
        <span>{{ linkLabel }}</span>
      </LangRouterLink>
      <button v-if="route && route === 'back'" class="link__details --reverse --button" @click="$router.back()">
        <Icon id="long-arrow" />
        <span>{{ linkLabel }}</span>
      </button>
      <h1 class="banner__title" v-scroll-reveal="{delay: 400}">{{ title }}</h1>
      <h2 v-if="subTitle" v-scroll-reveal="{delay: 500}">{{ subTitle }}</h2>
      <p v-if="description" v-scroll-reveal="{delay: 600}">{{ description }}</p>
    </div>
    <div class="banner__image" v-scroll-reveal="{delay: 200}">
      <div class="banner__image-fade"></div>
      <div class="banner__image-fade"></div>
      <img :src="image" alt="" class="banner__image-img">
    </div>
  </header>
</template>

<script>
import {LangRouterLink} from "@/components/global";
import Icon from "@/components/global/Icon";

export default {
  name: 'Banner',
  props: {
    content: {
      type: Object,
      default: null
    }
  },
  components: {
    Icon,
    LangRouterLink
  },
  computed: {
    description() {
      return this.content.description
    },
    image() {
      return this.content.image
    },
    linkLabel() {
      return this.content.toLabel
    },
    route() {
      return this.content.to
    },
    subTitle() {
      return this.content.subTitle
    },
    title() {
      return this.content.title
    }
  }
}
</script>
