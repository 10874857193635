<template>
  <section class="section --centered --ultra-wide">
    <TitleBlock :content="titleSection" class="block--center --max"/>
    <ul class="tips">
      <TipCard v-for="(tip, tipKey) in content.tips" :key="tipKey" :content="tip" v-scroll-reveal="{delay: 200 * tipKey + 200}"/>
    </ul>
    <LangRouterLink :to="{ name: 'health', hash: '#tips' }" class="button --primary --centered --margin">{{ $t('general.tips') }}</LangRouterLink>
  </section>
</template>

<script>
import { TipCard, TitleBlock } from '@/components'
import LangRouterLink from "@/components/global/LangRouterLink";

export default {
  name: 'TipsSection',
  components: {
    TitleBlock,
    LangRouterLink,
    TipCard
  },
  props: {
    content: {
      type: Object,
      default: null
    }
  },
  computed: {
    titleSection() {
      return {
        description: this.content.description,
        subTitle: this.content.subTitle,
        title: this.content.title
      }
    },
  }
}
</script>
