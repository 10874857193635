<template>
  <li class="detailed-dropdown">
    <button @click="toggleOpen" class="detailed-dropdown__button">
      <img :src="icon" alt="" class="detailed-dropdown__icon">
      <span class="detailed-dropdown__toggle">
        <Icon :id="isOpen ? 'minus' : 'plus'" />
      </span>
      <h3>{{ title }}</h3>
    </button>
    <DropDown :is-open="isOpen" >
      <div class="detailed-dropdown__content">
        <slot></slot>
      </div>
    </DropDown>
  </li>
</template>

<script>
import { DropDown } from '@/components/global';

export default {
  name: 'DetailedDropdownGroup',
  components: {
    DropDown
  },
  data() {
    return {
      isOpen: false
    }
  },
  props: {
    content: {
      type: Object,
      default: null
    }
  },
  computed: {
    currentLang() {
      return this.$i18n.locale
    },
    icon() {
      return this.content.icon ? this.content.icon : this.content.image
    },
    title() {
      return this.content.title ? this.content.title : this.content.name
    }
  },
  methods: {
    toggleOpen() {
      this.isOpen = !this.isOpen
    }
  }
}
</script>
