import { createRouter, createWebHistory } from 'vue-router'
import { generateRoutes } from '@/extensions/langRouter'

const NotFound = () => import('@/views/NotFound.vue')

const routes = [
    {
        path: '/',
        name: 'home',
        component: () => import('@/views/Home.vue')
    },
    {
        path: '/a-propos-de-nous',
        paths: { en: '/about-us' },
        name: 'about',
        component: () => import('@/views/About')
    },
    {
        path: '/accessibilite',
        paths: { en: '/accessibility' },
        name: 'accessibility',
        component: () => import('@/views/Accessibility')
    },
    {
        path: '/confidentialite',
        paths: { en: '/privacy' },
        name: 'policy',
        component: () => import('@/views/Policy')
    },
    {
        path: '/devenir-membre',
        paths: { en: '/become-a-member' },
        name: 'become-member',
        component: () => import('@/views/BecomeMember')
    },
    {
        path: '/carrieres',
        paths: { en: '/careers' },
        name: 'careers',
        component: () => import('@/views/Careers')
    },
    {
        path: '/concours-de-financement',
        paths: { en: '/funding-opportunities' },
        name: 'contest',
        component: () => import('@/views/Contest')
    },
    {
        path: '/documentation',
        paths: { en: '/documentation' },
        name: 'documentation',
        component: () => import('@/views/Documentation')
    },
    {
        path: '/evenements',
        paths: { en: '/events' },
        name: 'events',
        component: () => import('@/views/Events')
    },
    {
        path: '/evenements/:eventSlug',
        paths: { en: '/events/:eventSlug' },
        name: 'event',
        component: () => import('@/views/EventSingle'),
        props: true
    },
    {
        path: '/sante-durable',
        paths: { en: '/sustainable-health' },
        name: 'health',
        component: () => import('@/views/Health')
    },
    {
        path: '/membres',
        paths: { en: '/members' },
        name: 'members',
        component: () => import('@/views/Members')
    },
    {
        path: '/membres/:memberSlug',
        paths: { en: '/members/:memberSlug' },
        name: 'member',
        component: () => import('@/views/Member'),
        props: true
    },
    {
        path: '/actualites',
        paths: { en: '/news' },
        name: 'news',
        component: () => import('@/views/News')
    },
    {
        path: '/actualites/:newsSlug',
        paths: { en: '/news/:newsSlug' },
        name: 'news-single',
        component: () => import('@/views/NewsSingle'),
        props: true
    },
    {
        path: '/participer',
        paths: { en: '/to-participate' },
        name: 'participate',
        component: () => import('@/views/Participate')
    },
    {
        path: '/recherche',
        paths: { en: '/research' },
        name: 'poles-and-projects',
        component: () => import('@/views/PolesProjects')
    },
    {
        path: '/vie-etudiante',
        paths: { en: '/student-life' },
        name: 'students',
        component: () => import('@/views/Students')
    },
    {
        path: '/404',
        paths: { en: '/404' },
        name: 'not-found',
        component: NotFound
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'catch-all',
        component: NotFound
    }
]

const localizedRoutes = generateRoutes(routes, 'fr', ['en'])

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    linkActiveClass: '-active',
    linkExactActiveClass: '-exact-active',
    routes: localizedRoutes,
    scrollBehavior(to) {
        if (to.hash) {
            return {
                el: to.hash,
                behavior: "smooth"
            }
        }
        return { top: 0, behavior: 'smooth' }
    }
})

export default router
