<template>
  <section class="image-call-to">
    <div class="image-call-to__content">
      <TitleBlock :content="titleSection" />
      <LangRouterLink v-if="content.to" :to="{ name: content.to }" class="button --primary">{{ $t('general.concept') }}</LangRouterLink>
    </div>
    <div class="banner__image" v-scroll-reveal>
      <div class="banner__image-fade"></div>
      <img :src="content.image" alt="" class="banner__image-img">
    </div>
  </section>
</template>

<script>
import { LangRouterLink } from '@/components/global';
import { TitleBlock } from "@/components";
export default {
  name: 'ImageCallTo',
  components: {LangRouterLink, TitleBlock},
  props: {
    content: {
      type: Object,
      default: null
    }
  },
  computed: {
    titleSection() {
      return {
        description: this.content.description,
        subTitle: this.content.subTitle,
        title: this.content.title
      }
    }
  }
}
</script>
