<template>
  <div :class="{'mobile-nav--max': isMenuOpen}" class="mobile-nav">
    <header class="mobile-nav__header">
      <LangRouterLink :to="{name:'home'}"><img src="@/assets/images/logo.svg" class="mobile-nav__logo" alt="" /></LangRouterLink>
      <div class="mobile-nav__buttons">
        <button @click="toggleContact" class="mobile-nav__contact"><img :src="contactImage" alt="" /></button>
        <button @click="toggleMenu"><Icon :id="isMenuOpen ? 'hamburger-close' : 'hamburger'" class="mobile-nav__hamburger"/></button>
      </div>
    </header>
    <DropDown :is-open="isMenuOpen" class="mobile-nav--fit" :contentClass="'mobile-nav--full'">
      <MobileNavPanel></MobileNavPanel>
    </DropDown>
    <DropDown :is-open="isContactOpen">
      <ContactPanel class="--mobile"/>
    </DropDown>
  </div>
</template>

<script>
import { DropDown, Icon} from "@/components/global";
import MobileNavPanel from "@/components/nav/MobileNavPanel.vue";
import { ContactPanel } from '@/components';

export default {
  name: 'MobileNav',
  data() {
    return {
      isContactOpen: false,
      isMenuOpen: false
    }
  },
  components: {
    ContactPanel,
    Icon,
    DropDown,
    MobileNavPanel
  },
  computed: {
    contactImage() {
      if(this.isContactOpen) {
        return require('@/assets/images/contact-close.svg')
      }
      return require('@/assets/images/contact.svg')
    }
  },
  methods: {
    toggleContact() {
      this.isContactOpen = !this.isContactOpen
      if(this.isContactOpen === true && this.isMenuOpen === true) {
        this.isMenuOpen = false
      }
    },
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen
      if(this.isContactOpen === true && this.isMenuOpen === true) {
        this.isContactOpen = false
      }
    }
  },
  watch: {
    isContactOpen() {
      this.isContactOpen || this.isMenuOpen ? document.body.classList.add('--lock') : document.body.classList.remove('--lock')
    },
    isMenuOpen() {
      this.isMenuOpen || this.isContactOpen ? document.body.classList.add('--lock') : document.body.classList.remove('--lock')
    },
    '$route' () {
      this.isMenuOpen = false
    }
  }
}
</script>
