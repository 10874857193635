<template>
  <li class="icon-call-to">
    <img :src="icon" alt="" class="icon-call-to__icon">
    <h4 class="icon-call-to__title">{{ content.name }}</h4>
    <p>{{ content.description }}</p>
    <LangRouterLink :to="{ name: content.link }" class="button --primary icon-call-to__button">{{ $t('general.learnMore') }}</LangRouterLink>
  </li>
</template>

<script>
import LangRouterLink from "@/components/global/LangRouterLink";
export default {
  name: 'IconCallTo',
  components: {LangRouterLink},
  props: {
    content: {
      type: Object,
      default: null
    }
  },
  computed: {
    icon() {
      return this.content.icon ? this.content.icon : this.content.image
    }
  }
}
</script>
