<template>
  <section class="section stats --wide">
    <TitleBlock :content="titleSection" class="--max" />
    <ul class="stats__list">
      <StatCard v-for="(stat, statKey) in content.stats" :key="statKey" :content="stat" v-scroll-reveal="{ delay: 100 * statKey + 200}"/>
    </ul>
    <LangRouterLink v-if="content.to" :to="{ name: content.to }" class="button --primary --centered">{{ $t('general.who') }}</LangRouterLink>
  </section>
</template>

<script>
import { StatCard, TitleBlock } from '@/components'
import { LangRouterLink } from "@/components/global";
export default {
  name: 'StatsSection',
  components: {
    LangRouterLink,
    StatCard,
    TitleBlock
  },
  props: {
    content: {
      type: Object,
      default: null
    }
  },
  computed: {
    titleSection() {
      return {
        description: this.content.description,
        subTitle: this.content.subTitle,
        title: this.content.title
      }
    }
  }
}
</script>
