<template>
  <section class="section --wide block--center">
    <TitleBlock :content="titleSection" />
    <ul class="stats__list --adn">
      <li v-for="(item, itemKey) in items" :key="itemKey" class="stats__adn" v-scroll-reveal="{delay: 200 * itemKey + 300}">
        <img :src="item.image" alt="" class="stats__adn-icon">
        <h4>{{ item.title }}</h4>
        <p>{{ item.description }}</p>
      </li>
    </ul>
  </section>
</template>

<script>
import { TitleBlock } from "@/components";
export default {
  name: 'IconBlocksSection',
  components: {
    TitleBlock
  },
  props: {
    content: {
      type: Object,
      default: null
    }
  },
  computed: {
    titleSection() {
      return {
        subTitle: this.content.subTitle,
        title: this.content.title
      }
    },
    items() {
      return this.content.items
    }
  }
}
</script>
