<template>
  <div class="title-block">
    <h2 v-if="title" class="title-block__title" v-scroll-reveal="{delay: 400}">{{ title }}</h2>
    <h3 v-if="subTitle" class="title-block__subTitle" v-scroll-reveal="{ delay: 600}">{{ subTitle }}</h3>
    <p v-if="description" v-scroll-reveal="{ delay: 700}">{{ description }}</p>
  </div>
</template>

<script>
export default {
  name: 'TitleBlock',
  props: {
    content: {
      type: Object,
      default: null
    }
  },
  computed: {
    description() {
      return this.content.description
    },
    subTitle() {
      return this.content.subTitle
    },
    title() {
      return this.content.title
    }
  }
}
</script>
