<template>
  <div>
    <Transition name="fade">
      <section v-show="isOpen" class="contact__modal">
        <div class="contact__modal-background" @click="toggleOpen"></div>
        <ContactPanel class="--desktop"/>
      </section>
    </Transition>
    <button @click="toggleOpen" class="contact__button"><img :src="contactIcon" alt=""></button>
  </div>
</template>

<script>
import ContactPanel from '@/components/blocks/ContactPanel.vue';
export default {
  name: 'DesktopContact',
  components: {ContactPanel},
  data() {
    return {
      isBottom: false,
      isOpen: false
    }
  },
  computed: {
    contactIcon() {
      if(this.isOpen) {
        return require('@/assets/images/contact-close.svg')
      }
      return require('@/assets/images/contact.svg')
    }
  },
  methods: {
    toggleOpen() {
      this.isOpen = !this.isOpen
      if(this.isOpen) {
        document.getElementsByClassName('grecaptcha-badge')[0].style.visibility = 'visible'
      }
      else {
        document.getElementsByClassName('grecaptcha-badge')[0].style.visibility = 'hidden'
      }
    }
  },
  watch: {
    isOpen() {
      this.isOpen ? document.body.classList.add('--lock') : document.body.classList.remove('--lock')
    }
  }
}
</script>
