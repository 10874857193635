<template>
  <li class="stats__card">
    <img :src="icon" alt="" class="stats__card-icon">
    <h4 class="stats__card-title">{{ title }}</h4>
    <h5 class="stats__card-subTitle">{{ subTitle }}</h5>
    <p>{{ description }}</p>
  </li>
</template>

<script>
export default {
  name: 'StatCard',
  props: {
    content: {
      type: Object,
      default: null
    }
  },
  computed: {
    description() {
      return this.content.description
    },
    icon() {
      return this.content.icon ? this.content.icon : this.content.image
    },
    subTitle() {
      return this.content.title
    },
    title() {
      return this.content.number
    }
  }
}
</script>
