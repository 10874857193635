<template>
  <section class="section research-poles" id="projects">
    <h2 class="research-poles__title" v-scroll-reveal="{delay: 300}">{{ title }}</h2>
    <p v-scroll-reveal="{delay: 400}">{{ description }}</p>
    <ul class="research-poles">
      <ResearchProject v-for="(project, key) in projects" :key="key" :content="project" v-scroll-reveal="{delay: 200 * key + 300}"/>
    </ul>
  </section>
</template>

<script>
import { ResearchProject } from '@/components';

export default {
  name: 'ResearchProjects',
  props: {
    content: {
      type: Object,
      default: null
    }
  },
  components: {
    ResearchProject
  },
  computed: {
    description() {
      return this.content.description
    },
    projects() {
      return this.content.projects
    },
    title() {
      return this.content.title
    }
  }
}
</script>
