export default {
    gdpr: {
        banner: {
            title: 'Notre utilisation des témoins (cookies)',
            description: 'Ce site utilise des témoins (cookies) sur votre appareil. Nous utilisons ces informations afin d’améliorer et de personnaliser votre expérience de navigation. Les témoins seront activés seulement si vous acceptez.',
            accept: 'Accepter',
            settings: 'Gérer les préférences'
        },

        settings: {
            title: 'Notre utilisation des témoins (cookies)',
            description: 'Lorsque vous visitez ce site, celui-ci peut stocker ou récupérer des informations sur votre navigateur, principalement sous forme de témoins (cookies). Ces informations ne permettent pas de vous identifier directement, elles sont utilisées pour faciliter la navigation et offrir une expérience plus personnalisée.\n' +
                'Parce que nous respectons votre droit à la vie privée, vous pouvez choisir de ne pas autoriser certains types de témoins en modifiant les paramètres définis par défaut.\n' +
                'Pour plus d\'informations sur les témoins utilisés, consultez notre page Confidentialité.',
            essentials: {
                title: 'Témoins essentiels',
                description: 'Les témoins essentiels activent des fonctionnalités de base telles que la sécurité, la gestion du réseau et l\'accessibilité. Vous pouvez les désactiver en modifiant les paramètres de votre navigateur, mais cela peut affecter le fonctionnement du site Web.'
            },
            analytics: {
                title: 'Fichiers de témoins d’analyse',
                description: 'Les fichiers témoins Google Analytics nous permettent d’améliorer notre site Web en collectant et en rapportant des informations sur la façon dont vous l\'utilisez. Les fichiers témoins collectent des informations de manière complètement anonyme. Pour plus d\'informations sur le fonctionnement de ces fichiers témoins, veuillez consulter notre page Confidentialité.',
            },
            save: 'Enregistrer et fermer'
        },


    }
}

