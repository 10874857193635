<template>
 <section class="section --suggested" v-if="content.items.length > 0">
   <TitleBlock :content="titleSection" class="--max" />
   <div class="excerpt-card__swiper-container">
     <ul class="excerpt-card__swiper">
       <ExcerptCard v-for="(item, itemKey) in content.items" :key="itemKey" :content="item" class="--swipe" v-scroll-reveal="{delay: 200 * itemKey + 200}"/>
     </ul>
   </div>
   <LangRouterLink v-if="content.hasButton" :to="{ name: 'news' }" class="button --primary --centered --margin">{{ $t('general.news.all') }}</LangRouterLink>
 </section>
</template>

<script>
import { ExcerptCard, TitleBlock } from '@/components';
import LangRouterLink from "@/components/global/LangRouterLink";

export default {
  name: 'ExcerptCardsSuggestedSection',
  components: {
    ExcerptCard,
    LangRouterLink,
    TitleBlock
  },
  props: {
    content: {
      type: Object,
      default: null
    }
  },
  computed: {
    titleSection() {
      return {
        subTitle: this.content.subTitle,
        title: this.content.title
      }
    }
  }
}
</script>
