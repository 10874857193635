<template>
  <div class="navbar">
    <MobileNav></MobileNav>
    <div class="navbar__laptop">
      <LangRouterLink :to="{name:'home'}" class="navbar__link">
        <img src="@/assets/images/logo.svg" class="navbar__logo" alt=""/>
      </LangRouterLink>
      <ul class="navbar__links">
        <li v-for="(link, key) in links" :key="key" class="navbar__relative" :class="{'--sub-active': subIsActive(link.subRoutes)}">
          <LangRouterLink v-if="link.to" :to="link.to" class="navbar__link">{{ link.label[currentLang] }}</LangRouterLink>
          <div v-else>
            <button class="navbar__link" @mouseover="(openPanel(key))">
              {{ link.label[currentLang] }}
            </button>
            <Transition name="fade">
              <ul class="navbar__sublinks" v-show="panelOpen === key" @mouseleave="closePanel">
                <Icon id="triangle" class="navbar__triangle" />
                <li v-for="(subLink, subLinkKey) in link.subLinks" :key="subLinkKey">
                  <LangRouterLink :to="subLink.to">{{ subLink.label[currentLang] }}</LangRouterLink>
                </li>
              </ul>
            </Transition>
          </div>
        </li>
      </ul>
      <div class="navbar__buttons">
        <LangRouterLink :to="{name:'participate'}" class="button --primary --participate">{{ $t('general.participate') }}</LangRouterLink>
        <div @click="$i18n.locale = otherLang.toLowerCase()" class="button--round -empty">{{ otherLang }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { LangRouterLink } from '@/components/global'
import { MobileNav } from './'

export default {
  name: 'SiteNav',
  components: {
    LangRouterLink,
    MobileNav
  },
  data() {
    return {
      panelOpen: null,
    }
  },
  computed: {
    currentLang() {
      return this.$i18n.locale
    },
    links() {
      return this.$store.getters.getNav
    },
    otherLang() {
      return this.currentLang === 'fr' ? 'EN' : 'FR'
    }
  },
  methods: {
    closePanel() {
      this.panelOpen = null
    },
    openPanel(index) {
      this.panelOpen = index
    },
    subIsActive(input) {
      const paths = Array.isArray(input) ? input : [input]
      return paths.some(path => {
        return this.$route.path.toString().indexOf(path.toString()) !== -1
      })
    }
  },
  watch: {
    '$route' () {
      this.panelOpen = null
    }
  }
}
</script>
