<template>
  <section class="section block--center">
    <TitleBlock :content="titleSection" />
    <ul v-if="teams" class="detailed-dropdown-group">
      <DetailedDropdown v-for="(team, key) in teams" :key="key" :content="team">
        <ul v-if="team.members" class="team">
          <li v-for="(member, memberKey) in team.members" :key="memberKey" class="team__item">
            <a :href="member.link" class="team__member" :class="{'--disabled': member.link === ''}">
              <div class="team__member-infos">
                <h5 class="team__member-title">{{ member.name }}</h5>
                <p>{{ member.role }}</p>
              </div>
              <Icon v-if="member.link" id="long-arrow" class="team__member-icon" />
            </a>
          </li>
        </ul>
      </DetailedDropdown>
    </ul>
  </section>
</template>

<script>
import { DetailedDropdown, TitleBlock } from '@/components';
export default {
  name: 'TeamSection',
  components: {
    DetailedDropdown,
    TitleBlock
  },
  props: {
    content: {
      type: Object,
      default: null
    }
  },
  computed: {
    teams() {
      return this.content.teams
    },
    titleSection() {
      return {
        description: this.content.description,
        subTitle: this.content.subTitle,
        title: this.content.title
      }
    }
  }
}
</script>
