<template>
  <section class="section">
    <TitleBlock :content="titleSection" />
    <Transition name="fade">
      <DetailedCardsGroup v-if="content.opportunities.length > 0" :cards="content.opportunities" :buttonLabel="content.buttonLabel" />
    </Transition>
    <Transition name="fade">
      <p v-if="content.opportunities.length === 0" class="bold">{{ $t('general.careers.empty') }}</p>
    </Transition>
  </section>
</template>

<script>
import { DetailedCardsGroup, TitleBlock } from '@/components'

export default {
  name: 'OpportunitiesSection',
  components: {
    DetailedCardsGroup,
    TitleBlock
  },
  props: {
    content: {
      type: Object,
      default: null
    }
  },
  computed: {
    titleSection() {
      return {
        description: this.content.description,
        subTitle: this.content.subTitle,
        title: this.content.title
      }
    },
  }
}
</script>
