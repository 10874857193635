<template>
  <section v-if="content" class="text-image-block" v-scroll-reveal>
    <div class="text-image-block__content block__content">
      <h2 class="block__title" v-scroll-reveal="{delay: 400}">{{ content.title }}</h2>
      <h3 class="block__subtitle" v-scroll-reveal="{delay: 500}">{{ content.subTitle }}</h3>
      <p v-scroll-reveal="{delay: 600}">{{ content.text }}</p>
      <LangRouterLink v-if="content.to" :to="{ name: content.to }" class="button --primary" v-scroll-reveal="{delay: 700}">{{ $t(`general.${this.content.buttonLabel}`) }}</LangRouterLink>
    </div>
    <img :src="content.image" alt="" class="text-image-block__image" v-scroll-reveal="{delay: 300}">
  </section>
</template>

<script>
import { LangRouterLink } from '@/components/global';
export default {
  name: "TextImageBlock",
  components: {LangRouterLink},
  props: {
    content: {
      type: Object,
      default: null
    }
  }
}
</script>
