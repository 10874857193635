<template>
  <section>
    <TitleBlock :content="titleSection" class="section --centered --max"/>
    <div class="actions">
      <div class="actions__content">
        <ul v-scroll-reveal="{delay: 400}">
          <li v-for="(action, actionKey) in actions" :key="actionKey" v-show="currentStep === actionKey">
            <h4 class="actions__content-title">{{ action.title }}</h4>
            <p>{{ action.description }}</p>
          </li>
        </ul>
        <div class="actions__buttons">
          <a :href="buttonLink" v-if="isExternal(buttonLink)" target="_blank" class="button --primary">{{
              buttonLabel
            }}</a>
          <LangRouterLink v-else :to="buttonLink" class="button --primary" v-scroll-reveal="{delay: 500}">{{
              buttonLabel
            }}
          </LangRouterLink>
          <div class="button__group">
            <button class="button--round -reverse" :class="this.currentStep === 0 ? '-empty' : '-full'"
                    @click="lessStep" v-scroll-reveal="{delay: 600}">
              <Icon id="arrow"/>
            </button>
            <button class="button--round" :class="this.currentStep === this.maxStep ? '-empty' : '-full'"
                    @click="moreStep" v-scroll-reveal="{delay: 700}">
              <Icon id="arrow"/>
            </button>
          </div>
        </div>
      </div>
      <div class="banner__image --actions" v-scroll-reveal="{delay: 200}">
        <div class="banner__image-fade"></div>
        <img :src="image" alt="" class="banner__image-img">
      </div>
    </div>
  </section>
</template>

<script>
import {TitleBlock} from "@/components";
import {LangRouterLink} from "@/components/global";

export default {
  name: 'ActionsSection',
  data() {
    return {
      currentStep: 0,
    }
  },
  components: {LangRouterLink, TitleBlock},
  props: {
    content: {
      type: Object,
      default: null
    }
  },
  computed: {
    actions() {
      return this.content.actions
    },
    buttonLabel() {
      return this.actions[this.currentStep].label
    },
    buttonLink() {
      const link = this.actions[this.currentStep].link
      if (link.indexOf('http') === -1) return JSON.parse(link)
      return link
    },
    currentLang() {
      return this.$i18n.locale
    },
    image() {
      return this.content.image
    },
    maxStep() {
      return Math.round(this.actions.length) - 1
    },
    titleSection() {
      return {
        description: this.content.description,
        subTitle: this.content.subTitle,
        title: this.content.title
      }
    },
    to() {
      return this.content.to
    }
  },
  methods: {
    isExternal(url) {
      return url.toString().indexOf('http') !== -1
    },
    lessStep() {
      if (this.currentStep > 0) {
        this.currentStep--
      }
    },
    moreStep() {
      if (this.currentStep < this.maxStep) {
        this.currentStep++
      }
    }
  }
}
</script>
