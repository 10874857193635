export default {
    contact: {
        confirmation: 'Message received. A member of our team will be in touch with you shortly.',
        description: 'We’re here to help.',
        errors: {
            email: {
                empty: 'Please enter an email.',
                pattern: 'Please enter a valid email.'
            },
            firstName: {
                empty: 'Please enter a first name.',
                pattern: 'Please enter a valid first name.'
            },
            message: {
                empty: 'Please enter a message.',
                pattern:'Please enter a valid message. (Maximum 500 characters)'
            },
            name: {
                empty: 'Please enter a name.',
                pattern: 'Please enter a valid name.'
            }
        },
        email: 'Email',
        firstName: 'First name',
        message: 'Your message',
        name: 'Name',
        newsletter: 'I want to subscribe to the VITAM newsletter',
        submit: 'Send',
        subTitle: 'Contact us',
        title: 'Got questions?',
    },
    filters: {
        allThemes: 'All themes',
        allTypes: 'All types',
        events: {
            future: 'Upcoming events',
            past: 'Past events'
        },
        filter: 'Filters',
        reset: 'Reset',
        search: 'Search',
        searchDocument: 'Search a document',
        searchMember: 'Search for a member',
        themes: 'Themes',
        types: 'Types'
    },
    footer: {
        discover: 'Discover VITAM',
        newsletter: {
            title: 'Subscribe',
            confirmation: 'Subscription successful!',
            description: 'Stay tuned to all things VITAM with our newsletter',
            placeholder: 'Email'
        }
    },
    general: {
        accessibility: 'Accessibility',
        policy: 'Privacy',
        applicate: 'Apply',
        application: 'Submit your application',
        careers: {
            empty: 'There are currently no job openings.'
        },
        center: 'Discover the centre',
        channel: 'See our channel',
        concept: 'Learn more about sustainable health',
        contact: 'Contact Information',
        contactUs: 'Contact us',
        date: 'Date',
        details: 'More details',
        document: {
            empty: 'No document available for the moment.'
        },
        events: {
            all: 'See all events',
            empty: 'No events available for the moment.',
            future: {
                empty: 'There are currently no upcoming events. Watch past talks in the Past events section!',
                link: 'See past events'
            },
            past: {
                empty: 'There is currently no past event at VITAM. Do not hesitate to consult the future Events section which is full of conferences to watch!',
                link: 'Consult future events'
            },
            return: 'Back to events',
            share: 'Share',
            suggested: {
                subTitle: 'Watch for upcoming events',
                title: 'Don\'t miss anything'
            },
            view: 'See our events'
        },
        health: 'Learn more about sustainable health',
        home: 'Back to homepage',
        learnMore: 'Find out more',
        learnMoreRead: 'Read on',
        location: 'Location',
        managers: 'Lead',
        members: {
            become: 'Become a member',
            profile: 'View the profil',
            return: 'Back to the directory'
        },
        news: {
            all: 'See all news',
            empty: 'No news available for the moment.',
            return: 'Back to news',
            share: 'Share',
            suggested: {
                subTitle: 'Check out more news',
                title: 'Stay tuned'
            },
        },
        notFound: 'Back to homepage',
        opportunities: 'View all opportunities',
        opportunitiesOpenings: 'See job openings',
        participate: 'Get involved',
        partner: 'Become a partner',
        poles: 'Explore our areas of research',
        projects: 'See our projects',
        readMore: 'Read more',
        researchThemes: 'Research areas',
        spontaneous: 'Submit an unsolicited application',
        subscription: 'Register',
        tips: 'See all the tips',
        view: 'View',
        who: 'Discover the centre'
    }
}
