import './startup'

import { createApp } from 'vue'
import mitt from 'mitt'
import App from '@/App.vue'
import router from '@/router'
import store from '@/store'
import i18n from '@/i18n'
import GlobalComponents from '@/components/global'
import BeetAPI from '@boite-beet/api-client'
import langRouter from '@/extensions/langRouter'
import {VueReCaptcha} from 'vue-recaptcha-v3'
import reveal from '@/extensions/reveal'

import '@/styles/app.scss'
import VueGtag from "vue-gtag";

const app = createApp(App)
    .use(i18n)
    .use(store)
    .use(router)
    .use(langRouter, 'fr')
    .use(reveal)
    .use(GlobalComponents)
    .use(BeetAPI, {store, apiUrl: process.env.VUE_APP_API_URL})
    .use(VueReCaptcha, {
        siteKey: process.env.VUE_APP_RECAPTCHA_KEY
    })

const emitter = mitt();
app.provide('emitter', emitter)

app.use(VueGtag, {
    config: {id: process.env.VUE_APP_GA_MEASUREMENT_ID},
    bootstrap: false,
    includes: [
        {id: process.env.VUE_APP_UA_MEASUREMENT_ID}
    ]
}, router);

app.mount('#app')
