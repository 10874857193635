<template>
  <div class="youtube-video__container" @click="toggleOpen">
    <Transition name="fade">
      <div v-show="isOpen" class="youtube-video__modal" id="container">
        <iframe class="youtube-video__video" v-if="videoLink" :src="videoLink"
                title="YouTube video player" frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen></iframe>
      </div>
    </Transition>
    <div class="youtube-video__preview" v-scroll-reveal="{delay: 300}">
      <Icon id="play" class="youtube-video__icon"/>
      <img :src="thumbnail" alt="" class="youtube-video">
    </div>
  </div>
</template>

<script>
import Icon from "@/components/global/Icon";

export default {
  name: 'YoutubeVideo',
  components: {Icon},
  data() {
    return {
      isOpen: false,
    }
  },
  props: {
    thumbnail: {
      type: String,
      default: null
    },
    videoId: {
      type: String,
      default: null
    }
  },
  computed: {
    videoLink() {
      return this.isOpen ? `https://www.youtube.com/embed/${this.videoId}` : null
    }
  },
  methods: {
    toggleOpen() {
      this.isOpen = !this.isOpen
    }
  },
  watch: {
    isOpen() {
      this.isOpen ? document.body.classList.add('--lock') : document.body.classList.remove('--lock')
    }
  }
}
</script>
