<template>
  <ul v-if="cards" class="detailed-cards-group">
    <DetailedCard v-for="(card, key) in cards" :key="key" :content="card" :buttonLabel="buttonLabel" v-scroll-reveal="{delay: 200 * key + 200}" />
  </ul>
</template>

<script>
import { DetailedCard } from "@/components";

export default {
  name: 'DetailedCardsGroup',
  components: {
    DetailedCard
  },
  props: {
    buttonLabel: {
      type: String,
      default: 'subscription'
    },
    cards: {
      type: Array,
      default: null
    }
  },
}
</script>
