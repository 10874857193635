<template>
  <section class="contact__panel" v-if="$beet.isReady">
    <div class="title-block">
      <h2 v-if="titleSection.title" class="title-block__title">{{ titleSection.title }}</h2>
      <h3 v-if="titleSection.subTitle" class="title-block__subTitle">{{ titleSection.subTitle }}</h3>
      <p v-if="titleSection.description">{{ titleSection.description }}</p>
    </div>
    <Transition name="fade">
      <form action="" method="post" class="contact__form" @submit="submit" v-if="!sent">
        <label for="firstName" class="visually-hidden">{{ $t('contact.firstName') }}</label>
        <input type="text" id="firstName" :placeholder="$t('contact.firstName')" class="contact__input" v-model="form.firstName">
        <p v-if="formErrors.firstNameErr !== ''" class="contact__form-error">{{ formErrors.firstNameErr }}</p>

        <label for="name" class="visually-hidden">{{ $t('contact.name') }}</label>
        <input type="text" id="name" :placeholder="$t('contact.name')" class="contact__input" v-model="form.lastName">
        <p v-if="formErrors.nameErr !== ''" class="contact__form-error">{{ formErrors.nameErr }}</p>

        <label for="email" class="visually-hidden">{{ $t('contact.email') }}</label>
        <input type="email" id="email" :placeholder="$t('contact.email')" class="contact__input" v-model="form.email">
        <p v-if="formErrors.emailErr !== ''" class="contact__form-error">{{ formErrors.emailErr }}</p>

        <label for="message" class="visually-hidden">{{ $t('contact.message') }}</label>
        <textarea id="message" :placeholder="$t('contact.message')" class="contact__input contact__textarea"
                  v-model="form.message"/>
        <p v-if="formErrors.messageErr !== ''" class="contact__form-error">{{ formErrors.messageErr }}</p>

        <div class="contact__checkbox-group">
          <input type="checkbox" id="newsletterCheckbox" name="newsletter" class="contact__checkbox" v-model="checkbox">
          <label for="newsletterCheckbox" class="contact__label">{{ $t('contact.newsletter') }}</label>
        </div>

        <button type="submit" class="button --primary">{{ $t('contact.submit') }}</button>
      </form>
    </Transition>
    <Transition name="fade">
      <div class="contact__confirmation" v-if="sent">
        <img :src="require('@/assets/images/confirmation.svg')" alt="" class="contact__confirmation-image">
        <p>{{ $t('contact.confirmation') }}</p>
      </div>
    </Transition>
  </section>
</template>

<script>
import http from "@/extensions/Http";

export default {
  name: 'ContactPanel',
  data() {
    return {
      form: {
        email: '',
        firstName: '',
        message: '',
        lastName: '',
      },
      formErrors: {
        emailErr: '',
        firstNameErr: '',
        messageErr: '',
        nameErr: '',
      },
      checkbox: false,
      sent: false
    }
  },
  computed: {
    titleSection() {
      return {
        description: this.$t('contact.description'),
        subTitle: this.$t('contact.subTitle'),
        title: this.$t('contact.title')
      }
    }
  },
  methods: {
    sendContact() {
      http.post('/contact', this.form).then(() => {
        this.sent = true
        this.form = {
          firstName: '',
          lastName: '',
          email: '',
          message: ''
        }
      }).catch(err => {
        console.log(err)
      })
    },
    sendNewsletter() {
      http.post('/newsletter', this.form).then(() => {
        this.sent = true
        this.form = {
          email: '',
        }
      }).catch(err => {
        console.log(err)
      })
    },
    submit() {
      event.preventDefault()
      this.$recaptcha('contact').then(() => {
        if (this.validateForm()) {
          this.sendContact()

          if (this.checkbox) {
            this.sendNewsletter()
          }
        }
      })
    },
    validateForm() {
      event.preventDefault()
      let areFieldsValid = [false, false, false, false]

      switch (this.validateField(this.form.firstName, /^[a-zA-ZÀ-ÿ\-' ]{1,50}$/)) {
        case 'empty':
          this.formErrors.firstNameErr = this.$t('contact.errors.firstName.empty')
          break
        case 'pattern' :
          this.formErrors.firstNameErr = this.$t('contact.errors.firstName.pattern')
          break
        case true :
          this.formErrors.firstNameErr = ''
          areFieldsValid[0] = true
      }

      switch (this.validateField(this.form.lastName, /^[a-zA-ZÀ-ÿ\-' ]{1,50}/)) {
        case 'empty':
          this.formErrors.nameErr = this.$t('contact.errors.name.empty')
          break
        case 'pattern' :
          this.formErrors.nameErr = this.$t('contact.errors.name.pattern')
          break
        case true:
          this.formErrors.nameErr = ''
          areFieldsValid[1] = true
      }

      switch (this.validateField(this.form.email, /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/)) {
        case 'empty':
          this.formErrors.emailErr = this.$t('contact.errors.email.empty')
          break
        case 'pattern' :
          this.formErrors.emailErr = this.$t('contact.errors.email.pattern')
          break
        case true:
          this.formErrors.emailErr = ''
          areFieldsValid[2] = true
      }

      switch (this.validateField(this.form.message, /^[a-zA-ZÀ-ÿ0-9?$@#()'!,+\-=_:.&€£*%\s]{0,500}$/)) {
        case 'empty':
          this.formErrors.messageErr = this.$t('contact.errors.message.empty')
          break
        case 'pattern' :
          this.formErrors.messageErr = this.$t('contact.errors.message.pattern')
          break
        case true:
          this.formErrors.messageErr = ''
          areFieldsValid[3] = true
      }

      return areFieldsValid.indexOf(false);
    },
    validateField(field, regex) {
      if (field === '') {
        return 'empty'
      } else {
        if (regex.test(field)) {
          return true
        }
        return 'pattern'
      }
    }
  }
}
</script>
