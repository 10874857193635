<template>
  <section v-if="content" class="block--center icon-call-to-section section">
    <TitleBlock :content="titleSection" class="--max" />
    <ul class="icon-call-to-section__list">
      <IconCallTo v-for="(item, key) in content.items" :key="key" :content="item" v-scroll-reveal="{delay: 200 * key + 500}"/>
    </ul>
  </section>
</template>

<script>
import { IconCallTo, TitleBlock } from "@/components";

export default {
  name: 'IconCallToSection',
  components: {
    TitleBlock,
    IconCallTo
  },
  props: {
    content: {
      type: Object,
      default: null
    }
  },
  computed: {
    titleSection() {
      return {
        description: this.content.description,
        subTitle: this.content.subTitle,
        title: this.content.title,
      }
    }
  }
}
</script>
