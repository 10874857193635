<template>
  <section class="section">
    <div class="research-theme__mobile">
      <TitleBlock :content="titleSection" />
      <div v-if="content.themes" class="button__group">
        <button class="button--round -reverse" :class="this.currentStep === 0 ? '-empty' : '-full'" @click="lessStep"><Icon id="arrow"/></button>
        <button class="button--round" :class="this.currentStep === this.maxStep ? '-empty' : '-full'" @click="moreStep"><Icon id="arrow"/></button>
      </div>
      <ul v-if="content.themes" class="research-theme__group">
        <li v-for="(theme, themeKey) in content.themes" :key="themeKey" class="research-theme" v-show="themeKey === currentStep * 2 || themeKey === currentStep * 2 + 1">
          <img :src="theme.icon" alt="" class="research-theme__icon">
          <div class="research-theme__content">
            <h4>{{ theme.title ? theme.title : theme.name }}</h4>
            <p>{{ theme.description }}</p>
          </div>
        </li>
      </ul>
      <LangRouterLink :to="to" class="button --primary --margin">{{ buttonLabel }}</LangRouterLink>
    </div>
    <div class="research-theme__table">
      <div class="research-theme__table-content">
        <TitleBlock :content="titleSection" />
        <div class="research-theme__table-buttons">
          <LangRouterLink :to="to" class="button --primary" v-scroll-reveal="{delay: 500}">{{ buttonLabel }}</LangRouterLink>
          <div v-if="content.themes" class="button__group">
            <button class="button--round -reverse" :class="this.currentStepDesktop === 0 ? '-empty' : '-full'" @click="lessStepDesktop" v-scroll-reveal="{delay: 600}"><Icon id="arrow"/></button>
            <button class="button--round" :class="this.currentStepDesktop === this.maxStepDesktop ? '-empty' : '-full'" @click="moreStepDesktop" v-scroll-reveal="{delay: 700}"><Icon id="arrow"/></button>
          </div>
        </div>
      </div>
      <ul v-if="content.themes" class="research-theme__group">
        <li v-for="(theme, themeKey) in content.themes" :key="themeKey" class="research-theme" v-show="isVisibleDesktop(themeKey)">
          <img :src="theme.icon" alt="" class="research-theme__icon">
          <div class="research-theme__content">
            <h4>{{ theme.title ? theme.title : theme.name }}</h4>
            <p v-if="theme.description && theme.description !== ''">{{ theme.description }}</p>
          </div>
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
import { Icon, LangRouterLink } from '@/components/global';
import { TitleBlock } from '@/components';

export default {
  name: 'ResearchThemesSection',
  components: {
    Icon,
    LangRouterLink,
    TitleBlock
  },
  props: {
    content: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      currentStep: 0,
      currentStepDesktop: 0
    }
  },
  computed: {
    buttonLabel() {
      return this.$t('general.projects')
    },
    maxStep() {
      return Math.round(this.content.themes.length / 2) - 1
    },
    maxStepDesktop() {
      return Math.round(this.content.themes.length / 4) - 1
    },
    titleSection() {
      return {
        description: this.content.description,
        subTitle: this.content.subTitle,
        title: this.content.title
      }
    },
    to() {
      return this.content.to
    }
  },
  methods: {
    isVisibleDesktop(key) {
      for(let i = 0; i < 4; i++) {
        if(key === this.currentStepDesktop * 4 + i) {
          return true
        }
      }
      return false
    },
    lessStep() {
      if(this.currentStep > 0) {
        this.currentStep--
      }
    },
    lessStepDesktop() {
      if(this.currentStepDesktop > 0) {
        this.currentStepDesktop--
      }
    },
    moreStep() {
      if(this.currentStep < this.maxStep) {
        this.currentStep++
      }
    },
    moreStepDesktop() {
      if(this.currentStepDesktop < this.maxStepDesktop) {
        this.currentStepDesktop++
      }
    }
  }
}
</script>
