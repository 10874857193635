<template>
  <div class="managers" v-if="managers">
    <h3 class="managers__title">{{ pluralManagersTitle() }}</h3>
    <ul class="managers__list">
      <Manager v-for="(manager, managerKey) in managers" :key="managerKey" :content="manager" />
    </ul>
  </div>
</template>

<script>
import { Manager } from '@/components'

export default {
  name: 'Managers',
  components: {
    Manager
  },
  props: {
    managers: {
      type: Array,
      default: null
    }
  },
  methods: {
    pluralManagersTitle() {
      if(this.managers.length > 1) {
        const pluralTitle = this.$t('general.managers') + 's'
        return this.$i18n.locale === 'fr' ? pluralTitle : `Co-${pluralTitle.toLowerCase()}`
      }
      return this.$t('general.managers')
    }
  }
}
</script>
