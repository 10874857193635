<template>
  <footer class="footer" id="footer">
    <section class="footer__header">
      <div class="footer__section">
        <h2 class="footer__title">{{ $t('general.contactUs') }}</h2>
        <ContactInfos/>
        <ContactMedias/>
      </div>
      <div class="footer__section -middle">
        <h2 class="footer__title">{{ $t('footer.discover') }}</h2>
        <ul class="footer__list">
          <li v-for="(link, key) in interactiveLinks" :key="key" class="footer__list-item">
            <a v-if="link.link.startsWith('https')" :href="link.link" :title="link.title" target="_blank">{{
                link.title
              }}</a>
            <LangRouterLink v-else :to="JSON.parse(link.link.toString())" :title="link.title">{{
                link.title
              }}
            </LangRouterLink>
          </li>
        </ul>
      </div>
      <div class="footer__section-wide">
        <form action="" method="post" class="footer__form" @submit="submit">
          <h2 class="footer__title">{{ $t('footer.newsletter.title') }}</h2>
          <p>{{ $t('footer.newsletter.description') }}</p>
          <Transition name="fade">
            <div v-if="formSubmitted" class="footer__form-confirmation">
              <img :src="require('@/assets/images/confirmation.svg')" alt="" class="contact__form-icon">
              <p>{{ $t('footer.newsletter.confirmation') }}</p>
            </div>
          </Transition>
          <div v-if="!formSubmitted">
            <input type="email" :placeholder="$t('footer.newsletter.placeholder')" class="footer__input"
                   v-model="form.email">
            <button type="submit" class="footer__send">
              <Icon id="send"/>
            </button>
          </div>
          <Transition name="fade">
            <p v-if="errMessage !== ''" class="contact__form-error --footer">{{ errMessage }}</p>
          </Transition>
        </form>
        <div>
          <p class="footer__presentation">{{ presentation }}</p>
          <ul class="footer__partners">
            <li v-for="(partner, partnerKey) in partners" :key="partnerKey" class="footer__partner">
              <img :src="partner.image" :alt="partner.name" :title="partner.name">
            </li>
          </ul>
        </div>
      </div>
    </section>
    <section class="footer__bottom">
      <p>{{ copyrights }}</p>
      <div class="footer__bottom-links">
        <LangRouterLink :to="{ name: 'policy'}" class="footer__bottom-link">{{ $t('general.policy') }}</LangRouterLink>
        |
        <LangRouterLink :to="{ name: 'accessibility'}" class="footer__bottom-link -end">{{ $t('general.accessibility') }}</LangRouterLink>
      </div>
    </section>
  </footer>
</template>

<script>
import {ContactInfos, ContactMedias} from '@/components/nav'
import {Icon, LangRouterLink} from "@/components/global";
import http from "@/extensions/Http";

export default {
  name: 'Footer',
  components: {
    LangRouterLink,
    Icon,
    ContactInfos,
    ContactMedias
  },
  data() {
    return {
      form: {
        email: ''
      },
      errMessage: '',
      formSubmitted: false
    }
  },
  computed: {
    copyrights() {
      return this.$beet.isReady ? this.$beet.options.footer.partners.copyrights : ''
    },
    interactiveLinks() {
      return this.$beet.isReady ? this.$beet.options.footer.discover : ''
    },
    partners() {
      return this.$beet.isReady ? this.$beet.options.footer.partners.items : ''
    },
    presentation() {
      return this.$beet.isReady ? this.$beet.options.footer.partners.text : ''
    }
  },
  methods: {
    submit() {
      event.preventDefault()
      this.$recaptcha('contact').then(() => {
        if (this.form.email === '') {
          this.errMessage = this.$t('contact.errors.email.empty')
        } else {
          const regex = new RegExp(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/)
          if (regex.test(this.form.email)) {
            http.post('/newsletter', this.form).then(() => {
              this.sent = true
              this.form = {
                email: '',
              }
            }).catch(err => {
              console.log(err)
            })
            this.formSubmitted = true
            this.errMessage = ''
          } else {
            this.errMessage = this.$t('contact.errors.email.pattern')
          }
        }
      })
    }
  }
}
</script>
