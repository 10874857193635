<template>
  <li v-if="content" class="detailed-card">
    <img :src="content.image" alt="" class="detailed-card__image" />
    <div class="detailed-card__content">
      <h3 class="detailed-card__title">{{ title }}</h3>
      <p>{{ content.description }}</p>
      <div class="detailed-card__links">
        <a v-if="detailsLink" :href="detailsLink" class="detailed-card__details" target="_blank">
          <span>{{ $t('general.details') }}</span>
          <Icon id="long-arrow" class="detailed-card__icon" />
        </a>
        <a v-if="content.applicationLink" :href="content.applicationLink" class="button --primary --short" target="_blank">{{ $t(`general.${this.buttonLabel}`) }}</a>
      </div>
    </div>
  </li>
</template>

<script>
import {Icon} from '@/components/global';

export default {
  name: 'DetailedCard',
  components: {
    Icon
  },
  props: {
    buttonLabel: {
      type: String,
      default: 'subscription'
    },
    content: {
      type: Object,
      default: null
    }
  },
  computed: {
    detailsLink() {
      return this.content.detailsLink ? this.content.detailsLink : this.content.externalLink
    },
    title() {
      return this.content.title ? this.content.title : this.content.name
    }
  }
}
</script>
