<template>
  <section id="poles" v-if="content" class="section research-poles">
    <h2 class="research-poles__title" v-scroll-reveal="{delay: 500}">{{ title }}</h2>
    <p class="research-poles__description" v-scroll-reveal="{delay: 600}">{{ description }}</p>
    <ul v-if="poles" class="detailed-dropdown-group">
      <DetailedDropdown v-for="(pole, key) in poles" :key="key" :content="pole"
                        v-scroll-reveal="{delay: key * 200 + 500}">
        <p class="detailed-dropdown__content-text">{{ pole.description }}</p>
        <Managers :managers="getManagers(pole)"/>
      </DetailedDropdown>
    </ul>
  </section>
</template>

<script>
import {DetailedDropdown, Managers} from '@/components'

export default {
  name: 'ResearchPoles',
  props: {
    content: {
      type: Object,
      default: null
    }
  },
  components: {
    DetailedDropdown,
    Managers
  },
  computed: {
    currentLang() {
      return this.$i18n.locale
    },
    description() {
      return this.content.description
    },
    poles() {
      return this.content.poles
    },
    text() {
      return this.content.text
    },
    title() {
      return this.content.title
    }
  },
  methods: {
    getManagers(pole) {
      return Object.values(this.$beet.members)
          .filter(member => pole.managers.includes(member.id))
          .sort((a, b) => {
            return a.name.split(' ').reverse()[0].toLowerCase().localeCompare(b.name.split(' ').reverse()[0].toLowerCase())
          })
    }
  }
}
</script>
