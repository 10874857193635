<template>
  <li class="managers__item">
    <LangRouterLink :to="{ name: 'member', params: { memberSlug: this.content.slugs[this.currentLang] }}" class="managers__link">
      <p>{{ formattedManager }}</p>
      <Icon id="long-arrow" class="managers__icon" />
    </LangRouterLink>
  </li>
</template>

<script>
import LangRouterLink from "@/components/global/LangRouterLink";
export default {
  name: 'Manager',
  components: {LangRouterLink},
  props: {
    content: {
      type: Object,
      default: null
    }
  },
  computed: {
    currentLang() {
      return this.$i18n.locale
    },
    formattedManager() {
      return this.content.name + ', ' + this.type[this.typeGenre]
    },
    link() {
      return this.content['profile_link']
    },
    name() {
      return this.content.name
    },
    type() {
      return Object.values(this.$beet.types.members).filter(type => type.id === this.content.types[0])[0]
    },
    typeGenre() {
      return this.content['isMale'] ? 'maleName' : 'femaleName'
    }
  }
}
</script>
