<template>
  <section class="section">
    <TitleBlock :content="titleSection" class="block--center --max"/>
    <div class="double-video__group">
      <YoutubeVideo v-for="(video, videoKey) in content.videos" :key="videoKey" :video-id="video.id" :thumbnail="video.image" class="--double" />
    </div>
    <div class="double-video__channel" v-scroll-reveal="{delay: 200}">
      <img :src="channelSection.icon" alt="" class="double-video__channel-icon" v-scroll-reveal="{delay: 300}">
      <div class="double-video__channel-content">
        <h4 v-scroll-reveal="{delay: 400}">{{ channelSection.title }}</h4>
        <p v-scroll-reveal="{delay: 500}">{{ channelSection.description }}</p>
      </div>
      <a :href="channelSection.link" target="_blank" class="link__details double-video__channel-link" v-scroll-reveal="{delay: 600}">
        <span>{{ channelSection.linkLabel }}</span>
        <Icon id="long-arrow" />
      </a>
    </div>
    </section>
</template>

<script>
import {Icon} from "@/components/global";
import { TitleBlock, YoutubeVideo } from '@/components';

export default {
  name: 'DoubleVideoSection',
  components: {
    Icon,
    TitleBlock,
    YoutubeVideo
  },
  props: {
    content: {
      type: Object,
      default: null
    }
  },
  computed: {
    channelSection() {
      return {
        description: this.content.channel.description,
        icon: this.content.channel.icon,
        link: this.content.channel.link,
        linkLabel: this.$t('general.channel'),
        title: this.content.channel.title
      }
    },
    titleSection() {
      return {
        description: this.content.description,
        subTitle: this.content.subTitle,
        title: this.content.title
      }
    }
  }
}
</script>
