<template>
  <li class="document">
    <h2 class="document__title">{{ content.title }}</h2>
    <h3>{{ type }}</h3>
    <div class="document__bottom">
      <p class="document__author" v-if="author">
        <Icon v-if="cardType === 'member'" id="research-theme" class="document__icon" />
        <span>{{ author }}</span>
      </p>
      <a v-if="link" :href="link" class="link__details document__link" target="_blank">
        <span>{{ viewLabel }}</span>
        <Icon id="long-arrow" />
      </a>
      <LangRouterLink v-if="content.to" :to="content.to" class="link__details document__link">
        <span>{{ viewLabel }}</span>
        <Icon id="long-arrow" />
      </LangRouterLink>
    </div>
  </li>
</template>

<script>
import {Icon, LangRouterLink} from "@/components/global";

export default {
  name: 'DocumentCard',
  components: {Icon, LangRouterLink},
  props: {
    content: {
      type: Object,
      default: null
    },
    cardType: {
      type: String,
      default: 'document'
    }
  },
  computed: {
    author() {
      return this.content.author !== ' ' ? this.content.author : null
    },
    link() {
      return this.content.link ?? this.content.file
    },
    type() {
      return this.content.type ? this.content.type : Object.values(this.$beet.types.documents).filter(type => type.id === this.content.typeId)[0].name
    },
    viewLabel() {
      return this.cardType === 'document' ? this.$t('general.view') : this.$t('general.details')
    }
  }
}
</script>