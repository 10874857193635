import {createStore} from 'vuex'

export default createStore({
    state: {
        navigation: [
                {
                    label: {en: 'Centre', fr: 'Notre centre'},
                    to: false,
                    subLinks: [
                        {
                            label: {en: 'About us', fr: 'À propos de nous'},
                            to: {name: 'about'}
                        },
                        {
                            label: {en: 'News', fr: 'Actualités'},
                            to: {name: 'news'}
                        },
                        {
                            label: {en: 'Events', fr: 'Événements'},
                            to: {name: 'events'}
                        },
                        {
                            label: {en: 'Careers', fr: 'Carrières'},
                            to: {name: 'careers'}
                        }
                    ],
                    isOpen: false,
                    subRoutes: [
                        '/a-propos-de-nous',
                        '/actualites',
                        '/evenements',
                        'carrieres',
                        '/en/about-us',
                        '/en/news',
                        '/en/events',
                        '/en/careers'
                    ]
                },
                {
                    label: {en: 'Sustainable health', fr: 'Santé durable'},
                    to: {name: 'health'},
                    subRoutes: ['/sante-durable', '/en/sustainable-health']
                },
                {
                    label: {en: 'Research', fr: 'Notre Recherche'},
                    to: false,
                    subLinks: [
                        {
                            label: {en: 'Research activities', fr: 'Activités de recherche'},
                            to: {name: 'poles-and-projects'}
                        },
                        {
                            label: {en: 'Members', fr: 'Bottin des membres'},
                            to: {name: 'members'}
                        },
                        {
                            label: {en: 'Become a member', fr: 'Devenir membre'},
                            to: {name: 'become-member'}
                        },
                        {
                            label: {en: 'Documents', fr: 'Documentation'},
                            to: {name: 'documentation'}
                        },
                        {
                            label: {en: 'Funding opportunities', fr: 'Concours de financement'},
                            to: {name: 'contest'}
                        }
                    ],
                    isOpen: false,
                    subRoutes: [
                        '/recherche',
                        '/membres',
                        '/devenir-membre',
                        '/documentation',
                        '/concours-de-financement',
                        '/en/research',
                        '/en/members',
                        '/en/become-member',
                        '/en/documentation',
                        '/en/funding-opportunities'
                    ]
                },
                {
                    label: {en: 'Student Life ', fr: 'Vie Étudiante'},
                    to: {name: 'students'},
                    subRoutes: ['/vie-etudiante', '/en/student-life']
                }
            ],
        membersSavedScroll: 0,
    },
    getters: {
        getMembersSavedScroll(state) {
            return state.membersSavedScroll
        },
        getNav(state) {
            return state.navigation
        }
    },
    mutations: {
        updateMembersSavedScroll(state, newPosition) {
            state.membersSavedScroll = newPosition
        }
    },
    actions: {},
    modules: {}
})
