<template>
  <section class="section research-poles__section">
    <div class="research-poles__content">
      <TitleBlock :content="titleSection"/>
      <YoutubeVideo :video-id="video" :thumbnail="content.thumbnail" />
      <LangRouterLink :to="{ name: 'poles-and-projects' }" class="button --primary --margin --desktop" v-scroll-reveal="{delay: 600}">{{ buttonLabel }}</LangRouterLink>
    </div>
    <ul class="research-poles --about">
      <li class="research-poles__items" v-for="(pole, poleKey) in content.poles" :key="poleKey" v-scroll-reveal="{delay: 200 * poleKey + 400}">
        <img :src="pole.icon" alt="" class="research-poles__items-icon">
        <h4>{{ pole.title }}</h4>
      </li>
    </ul>
    <LangRouterLink :to="{ name: 'poles-and-projects', hash: '#poles' }" class="button --primary --margin --mobile">{{ buttonLabel }}</LangRouterLink>
  </section>
</template>

<script>
import { TitleBlock } from "@/components";
import LangRouterLink from "@/components/global/LangRouterLink";
import YoutubeVideo from "@/components/YoutubeVideo";
export default {
  name: 'ResearchPolesSection',
  components: {
    YoutubeVideo,
    LangRouterLink,
    TitleBlock
  },
  props: {
    content: {
      type: Object,
      default: null
    }
  },
  computed: {
    buttonLabel() {
      return this.$t('general.poles')
    },
    titleSection() {
      return {
        description: this.content.description,
        subTitle: this.content.subTitle,
        title: this.content.title
      }
    },
    video() {
      return this.content.youtubeId.toString()
    }
  }
}
</script>
