<template>
  <li class="excerpt-card" v-if="content">
    <div class="excerpt-card__top">
      <div class="excerpt-card__image">
        <img :src="content.image" alt="" draggable="false" class="excerpt-card__image-img">
        <div v-if="content.date" class="excerpt-card__date">
          <Icon id="calendar" class="excerpt-card__date-icon" />
          <time>{{ content.date }}</time>
        </div>
      </div>
      <h4 class="excerpt-card__title">{{ content.title }}</h4>
      <p v-if="description">{{ description }}</p>
    </div>
    <LangRouterLink v-if="content.to" :to="content.to" class="link__details excerpt-card__link">
      <span v-if="content.date">{{ $t('general.details') }}</span>
      <span v-else>{{ $t('general.readMore') }}</span>
      <Icon id="long-arrow"/>
    </LangRouterLink>
  </li>
</template>

<script>
import {Icon, LangRouterLink} from '@/components/global';
export default {
  name: 'ExcerptCard',
  components: {Icon, LangRouterLink},
  props: {
    content: {
      type: Object,
      default: null
    }
  },
  computed: {
    description() {
      return this.content.description ? this.content.description.charAt(149) === '.' ? `${this.content.description.slice(0, 150).trim(' ')}..` : `${this.content.description.slice(0, 150).trim(' ')}...` : null
    }
  }
}
</script>
