<template>
 <section class="section --eventsSuggested">
   <div>
     <TitleBlock :content="titleSection" />
     <LangRouterLink :to="{ name: 'events'}" class="button --primary --desktop">{{ $t('general.events.all') }}</LangRouterLink>
   </div>
   <ul class="event__group">
     <ExcerptCard v-for="(event, eventKey) in content.events" :key="'event' + eventKey" :content="event" class="--eventSuggested" v-scroll-reveal="{delay: 200 * eventKey + 200}"/>
   </ul>
   <LangRouterLink :to="{ name: 'events'}" class="button --primary --mobile">{{ $t('general.events.all') }}</LangRouterLink>
 </section>
</template>

<script>
import { LangRouterLink } from '@/components/global';
import { ExcerptCard, TitleBlock } from '@/components';
export default {
  name: 'EventsSection',
  components: {
    TitleBlock,
    ExcerptCard,
    LangRouterLink
  },
  props: {
    content: {
      type: Object,
      default: null
    }
  },
  computed: {
    titleSection() {
      return {
        description: this.content.description,
        subTitle: this.content.subTitle,
        title: this.content.title
      }
    },
  }
}
</script>
