<template>
  <li class="tips__item">
    <img :src="content.image" alt="" class="tips__image">
    <div class="tips__content">
      <h4 :class="{'tips__detailed-title': detailed}">{{ content.title }}</h4>
      <p :class="{'tips__detailed-author': detailed}">{{ content.author }}</p>
      <RawHtml v-if="detailed && description" :html="description"></RawHtml>
      <a v-if="content.link" :href="content.link" target="_blank" class="link__details" v-scroll-reveal="{delay: 500}">
        <span>{{ $t('general.details') }}</span>
        <Icon id="long-arrow"/>
      </a>
    </div>
  </li>
</template>

<script>
import Icon from "@/components/global/Icon";
import RawHtml from "@/components/global/RawHtml";

export default {
  name: 'TipCard',
  components: {RawHtml, Icon},
  props: {
    content: {
      Type: Object,
      default: null
    },
    detailed: {
      Type: Boolean,
      default: false
    }
  },
  computed: {
    description() {
      return this.content.content ? this.content.content !== "" ? this.content.content : null : null
    }
  }
}
</script>
