<template>
  <li class="research-project" v-scroll-reveal>
    <img :src="content.image" alt="" class="research-project__image" v-scroll-reveal="{delay: 300}">
    <div class="research-project__content">
      <div class="research-project__main">
        <h3 class="research-poles__title" v-scroll-reveal="{delay: 400}">{{ content.title }}</h3>
        <p v-scroll-reveal="{delay: 500}">{{ content.description }}</p>
        <a v-if="detailsLink" :href="detailsLink" class="link__details" target="_blank" v-scroll-reveal="{delay: 600}">
          {{ $t('general.details') }}
          <Icon id="long-arrow" />
        </a>
      </div>
      <Managers v-if="managers && managers.length > 0" :managers="managers" class="--project" />
    </div>
  </li>
</template>

<script>
import {Icon} from '@/components/global'
import { Managers } from '@/components';

export default {
  name: 'ResearchProject',
  components: {
    Icon,
    Managers
  },
  props: {
    content: {
      type: Object,
      default: null
    }
  },
  computed: {
    detailsLink() {
      return this.content.externalLink.startsWith('https://') ? this.content.externalLink : `https://${this.content.externalLink}`
    },
    managers() {
      return Object.values(this.$beet.members)
          .filter(member => this.content.managers.includes(member.id))
          .sort((a, b) => {
            return a.name.split(' ').reverse()[0].toLowerCase().localeCompare(b.name.split(' ').reverse()[0].toLowerCase())
          })
    }
  }
}
</script>
