<template>
  <div class="mobile-nav-panel">
    <nav>
      <ul class="mobile-nav-panel__links">
        <li v-for="(link, key) in links" :key="key">
          <LangRouterLink v-if="link.to" :to="link.to" class="mobile-nav-panel__link">{{ link.label[currentLang] }}</LangRouterLink>
          <div v-else>
            <button @click="(updateIsOpen(link))" class="mobile-nav-panel__link">
              {{ link.label[currentLang] }}
              <Icon :id="link.isOpen ? 'minus' : 'plus'" class="mobile-nav-panel__link-icon" />
            </button>
            <DropDown :is-open="link.isOpen">
              <ul class="mobile-nav-panel__sublinks">
                <li v-for="(subLink, subLinkKey) in link.subLinks" :key="subLinkKey">
                  <LangRouterLink :to="subLink.to" class="mobile-nav-panel__sublink">{{ subLink.label[currentLang] }}</LangRouterLink>
                </li>
              </ul>
            </DropDown>
          </div>
        </li>
      </ul>
    </nav>
    <nav class="mobile-nav-panel__buttons">
      <LangRouterLink :to="{name:'participate'}" class="button --primary">{{ $t('general.participate') }}</LangRouterLink>
      <div @click="$i18n.locale = otherLang.toLowerCase()" class="button--round -empty"> {{otherLang}}</div>
    </nav>
    <ContactInfos />
    <ContactMedias/>
  </div>
</template>

<script>
import { DropDown, LangRouterLink } from '@/components/global';
import { ContactInfos, ContactMedias } from "@/components/nav";

export default {
  name: 'MobileNavPanel',
  components: {
    ContactInfos,
    ContactMedias,
    DropDown,
    LangRouterLink
  },
  computed: {
    currentLang() {
      return this.$i18n.locale
    },
    links() {
      return this.$store.getters.getNav
    },
    otherLang() {
      return this.currentLang === 'fr' ? 'EN' : 'FR'
    }
  },
  methods: {
    updateIsOpen(item) {
      item.isOpen = !item.isOpen
    }
  },
  watch: {
    '$route' () {
      this.links.forEach(link => {
        link.isOpen = false
      })
    }
  }
}
</script>
