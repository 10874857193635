<template>
  <section v-if="content" class="text-video-block section">
    <div class="text-video-block__content">
      <TitleBlock :content="titleBlock" />
      <a :href="to" v-if="to" class="button --primary --video" id="anchorButton" v-scroll-reveal="{delay: 400}">{{ buttonLabel }}</a>
    </div>
    <YoutubeVideo :video-id="video" :thumbnail="thumbnail" class="--block"/>
  </section>
</template>

<script>
import { TitleBlock, YoutubeVideo } from '@/components'

export default {
  name: 'TextVideoBlock',
  components: {
    TitleBlock,
    YoutubeVideo
  },
  props: {
    content: {
      type: Object,
      default: null
    }
  },
  computed: {
    buttonLabel() {
      return this.content.buttonLabel
    },
    thumbnail() {
      return this.content.thumbnail
    },
    video() {
      return this.content.youtubeId
    },
    titleBlock() {
      return {
        description: this.content.text,
        subTitle: this.content.subTitle,
        title: this.content.title
      }
    },
    to() {
      return this.content.to
    }
  }
}
</script>
