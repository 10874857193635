<template>
  <section class="section">
    <TitleBlock :content="titleSection" />
    <LangRouterLink v-if="redirect" :to="redirect" class="button --primary --centered" v-scroll-reveal="{delay: 500}">{{ redirectLabel }}</LangRouterLink>
    <a v-if="to" :href="'mailto:' + to" class="button --primary --centered" v-scroll-reveal="{delay: 600}">{{ content.buttonLabel }}</a>
  </section>
</template>

<script>
import { TitleBlock } from '@/components';
import LangRouterLink from "@/components/global/LangRouterLink";
export default {
  name: 'SpontaneousApplicationSection',
  components: {
    LangRouterLink,
    TitleBlock
  },
  props: {
    content: {
      type: Object,
      default: null
    }
  },
  computed: {
    redirect() {
      return this.content.redirect
    },
    redirectLabel() {
      return this.content.redirectLabel
    },
    titleSection() {
      return {
        description: this.content.description,
        subTitle: this.content.subTitle,
        title: this.content.title
      }
    },
    to() {
      return this.content.to
    }
  }
}
</script>