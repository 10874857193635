<template>
  <template v-if="$beet.isReady">
    <SiteNav />
    <router-view />
    <Footer />
    <DesktopContact />
    <button ref="scrollButton" class="button__scroll"><Icon id="arrow" /></button>
    <Gdpr/>
  </template>
  <Transition name="fade">
      <Loader v-if="!$beet.isReady" />
  </Transition>
</template>

<script>
import {Icon} from '@/components/global'
import { SiteNav } from "@/components/nav";
import { DesktopContact, Footer, Loader } from '@/components'
import {useLangRouter} from "@/extensions/langRouter";
import Gdpr from "@/components/gdpr/Gdpr.vue";

export default {
  name: 'App',
  setup() {
    useLangRouter()
  },
  components: {
    Gdpr,
    DesktopContact,
    Footer,
    Icon,
    Loader,
    SiteNav
  },
  watch: {
    '$i18n.locale': {
      handler: function(to) {
        this.$beet.fetchCoreData(to)
      },
      immediate: true
    }
  },
  mounted() {
    if(this.$beet.isReady) {
      this.setScrollButton()
    }
  },
  methods: {
    setScrollButton() {
      this.$refs.scrollButton.addEventListener('click', () => {
        window.scrollTo({
          top: 0,
          behavior: "smooth"
        })
      })
      window.addEventListener('scroll',function (){
        if(window.scrollY > 1800 && window.innerWidth < 1025) {
          if(!this.$refs.scrollButton.classList.contains('--visible')) {
            this.$refs.scrollButton.classList.add('--visible')
          }
        }
        else {
          if(this.$refs.scrollButton.classList.contains('--visible')) {
            this.$refs.scrollButton.classList.remove('--visible')
          }
        }
      })
    }
  }
}
</script>
